import React, {useEffect, createRef, useState, useMemo} from 'react';
import LitetechApi from '../../modules/litetech-api.js';
import useStateCallback from '../../modules/useStateCallback.js';
import {Checkbox} from 'react-ui-icheck';
import swal from 'sweetalert';
import Datetime from 'react-datetime';
import DateTimePickerConstraint from '../../components/common/DateTimePickerConstraint.js';
import moment from 'moment';
import Select from 'react-select';
import Table, {ActionCell, CheckboxCell, MomentCell} from '../../components/common/Table';

function ShiftsView() {
    const initialForm = {
        shiftId: null,
        suggestStartedAt: new Date(),
        suggestFinishedAt: new Date(),
        userId: null,
        zoneId: null,
        skillId: null,
        isApproved: false,
        userName: null,
    }
    const [editedShiftFormState, setEditedShiftFormState] = useState(initialForm);
    const [newShiftFormState, setNewShiftFormState] = useState(initialForm);
    const [usersState, setUsersState] = useState([])

    const [shifts, setShifts] = useStateCallback([])
    const [allowedStartMinutes, setAllowedStartMinutes] = useStateCallback([0])
    const myRef = createRef()

    useEffect(() => {
        const node = myRef.current;
        node.addEventListener('animationend', () => {
            node.classList.remove('animated');
        }, {once: true});

        loadAndRenderShifts()
        loadAllowedStartMinutes()
        loadUsers()
    }, [])

    const data = useMemo(
        () => shifts.map(({
                              shift_id: id,
                              suggest_started_at: suggestStartedAt,
                              suggest_finished_at: suggestFinishedAt,
                              started_at: startedAt,
                              finished_at: finishedAt,
                              is_approved: isApproved,
                              user: {
                                  first_name: firstName,
                                  last_name: lastName,
                              },
                          }) => ({
            col1: suggestStartedAt,
            col2: suggestFinishedAt,
            col3: startedAt,
            col4: finishedAt,
            col5: isApproved,
            col6: `${firstName} ${lastName}`,
            col7: {
                disabledEdit: isApproved,
                disabledApprove: isApproved,
                disabled: startedAt,
                editOnClick: editShift(id, suggestStartedAt, `${firstName} ${lastName}`),
                approveOnClick: approveShift(id, `${firstName} ${lastName}`),
                removeOnClick: removeShift(id, `${firstName} ${lastName}`),
            },
        })),
        [shifts],
    )

    const columns = useMemo(
        () => [
            {Header: 'Прелагаемое время начала', accessor: 'col1', Cell: MomentCell},
            {Header: 'Предлагаемое время окончания', accessor: 'col2', Cell: MomentCell, collapsed: true},
            {Header: 'Фактическое время начала', accessor: 'col3', Cell: MomentCell, collapsed: true},
            {Header: 'Фактическое время окончания', accessor: 'col4', Cell: MomentCell, collapsed: true},
            {Header: 'Подтверждена', accessor: 'col5', Cell: CheckboxCell, disableFilters: true},
            {Header: 'Имя', accessor: 'col6'},
            {
                Header: 'Действия',
                accessor: 'col7',
                Cell: ActionCell,
                disableSortBy: true,
                disableFilters: true,
                collapsed: true,
            },
        ],
        [],
    )

    async function loadAllowedStartMinutes() {
        const allowedHours = await LitetechApi.getAllowedStartMinutes()
        setAllowedStartMinutes(allowedHours)
    }

    async function loadAndRenderShifts() {
        const shifts = await LitetechApi.getAllShifts()
        if (shifts) {
            setShifts(shifts)
        }
    }

    async function loadUsers() {
        const users = await LitetechApi.getAllUsers()
        setUsersState(users.map(({user_id: id, first_name: firstName, last_name: lastName}) => ({
            value: id,
            label: firstName || lastName ? `${lastName || ''} ${firstName || ''}` : id,
        })))
    }

    function approveShift(shiftId, name) {
        return () => async () => {
            const wilApprove = await swal({
                title: `Подтвердить смену для ${name}?`,
                icon: 'warning',
                buttons: ['Отмена', true],
            })
            if (wilApprove) {
                await LitetechApi.approveShift(shiftId)
                await swal('', {
                    title: 'Смена подтверждена',
                    icon: 'success',
                });
                await loadAndRenderShifts()
            }
        }
    }

    function editShift(shiftId, suggestStartedAt, userName) {
        return () => () => {
            setEditedShiftFormState({
                shiftId,
                suggestStartedAt,
                suggestFinishedAt: new Date(moment(suggestStartedAt).add(12, 'hours').format()),
                userName,
            })
            console.log(editedShiftFormState);
            $('#editShiftModal').modal('show');
        }
    }

    function removeShift(shiftId, name) {
        return () => async () => {
            const wilApprove = await swal({
                title: `Удалить смену для ${name}?`,
                icon: 'warning',
                buttons: ['Отмена', true],
            })
            if (wilApprove) {
                await LitetechApi.removeShift(shiftId)
                await swal('', {
                    title: 'Смена удалена',
                    icon: 'success',
                });
                await loadAndRenderShifts()
            }
        }
    }

    function getDateHandler(isEdit = false) {
        return function dateHandler(event) {
            const {name, value} = event.target;
            if (name === 'suggestStartedAt') {
                if (isEdit)
                    setEditedShiftFormState({
                        ...editedShiftFormState,
                        suggestStartedAt: value,
                        suggestFinishedAt: new Date(moment(value).add(12, 'hours').format()),
                    })
                else
                    setNewShiftFormState({
                        ...newShiftFormState,
                        suggestStartedAt: value,
                        suggestFinishedAt: new Date(moment(value).add(12, 'hours').format()),
                    })
            } else {
                if (isEdit)
                    setEditedShiftFormState({
                        ...editedShiftFormState,
                        [name]: value,
                    })
                else
                    setNewShiftFormState({
                        ...newShiftFormState,
                        [name]: value,
                    })
            }
        }
    }

    function handleUser({value}) {
        setNewShiftFormState({
            ...newShiftFormState,
            userId: value,
        })
    }

    function handleChecked(event) {
        const {name, checked} = event.target;
        setNewShiftFormState({
            ...newShiftFormState,
            [name]: checked,
        })
    }

    async function createShift() {
        event.preventDefault()
        const willCreate = await swal({
            title: 'Создать смену?',
            icon: 'warning',
            buttons: ['Отмена', true],
        })
        if (willCreate) {
            await LitetechApi.createShift(newShiftFormState)
            await loadAndRenderShifts()
            $('#createShiftModal').modal('hide');
            await swal('', {
                title: 'Смена создана',
                icon: 'success',
            });
            setNewShiftFormState(initialForm)
        }
    }

    async function updateShift() {
        event.preventDefault()
        const willCreate = await swal({
            title: 'Изменить смену?',
            icon: 'warning',
            buttons: ['Отмена', true],
        })
        if (willCreate) {
            await LitetechApi.editShift(editedShiftFormState)
            await loadAndRenderShifts()
            $('#editShiftModal').modal('hide');
            await swal('', {
                title: 'Смена изменена',
                icon: 'success',
            });
            setEditedShiftFormState(initialForm)
        }
    }

    return (
        <div className='wrapper wrapper-content animated fadeInRight' ref={myRef}>
            <div className='row'>
                <div className='ibox '>
                    <div className='ibox-title'>
                        <h5>Смены</h5>
                    </div>
                    <div className='ibox-content'>
                        <Table data={data} columns={columns} modalName={'createShiftModal'}/>
                    </div>
                </div>
            </div>

            {/*Модалка редактирования смены*/}
            <div className='modal inmodal' id='editShiftModal' role='dialog' aria-hidden='true'>
                <div className='modal-dialog'>
                    <div className='modal-content animated bounceInRight'>
                        <div className='modal-header'>
                            <button type='button' className='close' data-dismiss='modal'><span
                                aria-hidden='true'>&times;</span><span className='sr-only'>Close</span>
                            </button>
                            <h4 className='modal-title'>Изменить смену</h4>
                        </div>
                        <form onSubmit={updateShift}>
                            <div className='modal-body'>
                                <div className='form-group'>
                                    <label>Прелагаемое время начала</label>
                                    <DateTimePickerConstraint allowedHoursArr={allowedStartMinutes}
                                                              name='suggestStartedAt'
                                                              value={editedShiftFormState.suggestStartedAt}
                                                              onChange={getDateHandler(true)}/>
                                </div>
                                <div className='form-group'>
                                    <label>Предлагаемое время окончания</label>
                                    <Datetime dateFormat='DD.MM.yyyy'
                                              value={editedShiftFormState.suggestFinishedAt}
                                              inputProps={{
                                                  disabled: true,
                                              }}/>
                                </div>
                                <div className='form-group'>
                                    <label>Техник</label>
                                    <div>
                                        {editedShiftFormState.userName}
                                    </div>
                                </div>
                            </div>
                            <div className='modal-footer'>
                                <button type='button' className='btn btn-white' data-dismiss='modal'>Отмена</button>
                                <button type='submit' className='btn btn-primary'>
                                    Изменить смену
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

            {/*Модалка создания смены*/}
            <div className='modal inmodal' id='createShiftModal' role='dialog' aria-hidden='true'>
                <div className='modal-dialog'>
                    <div className='modal-content animated bounceInRight'>
                        <div className='modal-header'>
                            <button type='button' className='close' data-dismiss='modal'><span
                                aria-hidden='true'>&times;</span><span className='sr-only'>Close</span>
                            </button>
                            <h4 className='modal-title'>Создать смену</h4>
                        </div>
                        <form onSubmit={createShift}>
                            <div className='modal-body'>
                                <div className='form-group'>
                                    <label>Прелагаемое время начала</label>
                                    <DateTimePickerConstraint allowedHoursArr={allowedStartMinutes}
                                                              name='suggestStartedAt'
                                                              value={newShiftFormState.suggestStartedAt}
                                                              onChange={getDateHandler(false)}/>
                                </div>
                                <div className='form-group'>
                                    <label>Предлагаемое время окончания</label>
                                    <Datetime dateFormat='DD.MM.yyyy'
                                              value={newShiftFormState.suggestFinishedAt}
                                              inputProps={{
                                                  disabled: true,
                                              }}/>
                                </div>
                                <div className='form-group'>
                                    <label>Техник</label>
                                    <Select
                                        // key={`select_key_${newShiftFormState.userId}`}
                                        styles={{
                                            control: (provided, state) => ({
                                                ...provided,
                                                borderRadius: 1,
                                                border: state.isFocused ? '1px solid #1ab394' : '1px solid #e5e6e7',
                                                boxShadow: 'none',
                                                '&:hover': 'none',
                                            }),
                                        }}
                                        placeholder='Поиск...'
                                        noOptionsMessage={() => 'Техник не найден'}
                                        value={usersState.find(({value}) => newShiftFormState.userId === value) || null}
                                        onChange={handleUser}
                                        options={usersState}
                                    />
                                </div>
                                {/* <div className="form-group">
                                    <label>Зона</label>
                                    <input onChange={handleValue}
                                           value={newShiftFormState.zoneId}
                                           required={true}
                                           name="zoneId"
                                           type="text"
                                           className="form-control"/>
                                </div>
                                <div className="form-group">
                                    <label>Навык</label>
                                    <input onChange={handleValue}
                                           value={newShiftFormState.skillId}
                                           required={true}
                                           name="skillId"
                                           type="text"
                                           className="form-control"/>
                                </div>*/}
                                <div className='form-group'>
                                    <label>Подтвердить</label>
                                    <Checkbox onChange={handleChecked}
                                              name='isApproved'
                                              label=' '
                                              checked={newShiftFormState.isApproved}
                                              checkboxClass='icheckbox_square-green'
                                    />
                                </div>
                            </div>
                            <div className='modal-footer'>
                                <button type='button' className='btn btn-white' data-dismiss='modal'>Отмена</button>
                                <button type='submit' className='btn btn-primary'>
                                    Создать смену
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ShiftsView
