import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import {HashRouter} from 'react-router-dom';
import Moment from 'react-moment';

import 'regenerator-runtime'

import 'jquery';
import 'metismenu';
import 'bootstrap';

import 'bootstrap/dist/css/bootstrap.min.css'
import 'font-awesome/css/font-awesome.css'
import 'animate.css/animate.min.css'
import '../public/styles/inspinia_style.css'
import '../public/styles/styles.css'
import 'icheck/skins/square/green.css';
import 'react-datetime/css/react-datetime.css';
import 'moment/locale/ru';

const root = ReactDOM.createRoot(
    document.getElementById('root'),
);

Moment.globalFormat = 'YYYY/MM/DD HH:mm';

root.render(
    <React.StrictMode>
        <HashRouter>
            <App/>
        </HashRouter>
    </React.StrictMode>,
);
