import React, {useEffect, useState, createRef, useMemo} from 'react';
import LitetechApi from '../modules/litetech-api';
import useStateCallback from '../modules/useStateCallback';
import {Checkbox} from 'react-ui-icheck';
import phoneFormat from '../modules/phoneFormat';
import swal from 'sweetalert';
import Table, {ActionCell, CheckboxCell} from '../components/common/Table';

function Users() {
    const initialForm = {
        firstname: '',
        lastname: '',
        login: '+7',
        password: '',
        isSenior: false,
    }
    const [formState, setFormState] = useState(initialForm);
    const [usersState, setUsersState] = useStateCallback([])
    const myRef = createRef()


    useEffect(() => {
        const node = myRef.current;
        node.addEventListener('animationend', () => {
            node.classList.remove('animated');
        }, {once: true});

        getUsers()
    }, [])

    const data = useMemo(
        () => usersState.map(({
                                  user_id: id,
                                  first_name: firstName,
                                  last_name: lastName,
                                  phone,
                                  is_senior: isSenior,
                              }) => ({
            col1: firstName,
            col2: lastName,
            col3: `+${phone}`,
            col4: isSenior,
            col5: {
                removeOnClick: removeUser(id, `${firstName} ${lastName}`),
            },
        })),
        [usersState],
    )

    const columns = useMemo(
        () => [
            {Header: 'Имя', accessor: 'col1'},
            {Header: 'Фамилия', accessor: 'col2'},
            {Header: 'Телефон', accessor: 'col3'},
            {Header: 'Старший', accessor: 'col4', Cell: CheckboxCell},
            {Header: 'Действия', accessor: 'col5', Cell: ActionCell, collapsed: true},
        ],
        [],
    )

    function handleValue(event) {
        const {name, value} = event.target;
        setFormState({
            ...formState,
            [name]: name === 'login' ? phoneFormat(value) : value,
        })
    }

    function handleChecked(event) {
        const {name, checked} = event.target;
        setFormState({
            ...formState,
            [name]: checked,
        })
    }

    async function getUsers() {
        const users = await LitetechApi.getAllUsers()
        if (users) setUsersState(users)
    }

    async function createUser(event) {
        event.preventDefault()
        const willCreate = await swal({
            title: 'Создать пользователя?',
            icon: 'warning',
            buttons: true,
        })
        if (willCreate) {
            await LitetechApi.createUser(formState)
            await getUsers()
            $('#createUserModal').modal('hide');
            await swal('', {
                title: 'Пользователь создан',
                icon: 'success',
            });
            setFormState(initialForm)
        }
    }

    function removeUser(userId, name) {
        return () => async () => {
            const wilApprove = await swal({
                title: `Удалить пользователя ${name}?`,
                icon: 'warning',
                buttons: ['Отмена', true],
            })
            if (wilApprove) {
                await LitetechApi.removeUser(userId)
                await getUsers()
                await swal('', {
                    title: 'Пользователь удалён',
                    icon: 'success',
                });
            }
        }
    }

    return (
        <div className='wrapper wrapper-content animated fadeInRight' ref={myRef}>
            <div className='row'>
                <div className='ibox '>
                    <div className='ibox-title'>
                        <h5>Пользователи</h5>

                        {/* <div className="ibox-tools">
                                    <a className="collapse-link">
                                        <i className="fa fa-chevron-up"></i>
                                    </a>
                                    <a className="dropdown-toggle" data-toggle="dropdown" href="#">
                                        <i className="fa fa-wrench"></i>
                                    </a>
                                    <ul className="dropdown-menu dropdown-user">
                                        <li><a href="#" className="dropdown-item">Config option 1</a>
                                        </li>
                                        <li><a href="#" className="dropdown-item">Config option 2</a>
                                        </li>
                                    </ul>
                                    <a className="close-link">
                                        <i className="fa fa-times"></i>
                                    </a>
                                </div>*/}
                    </div>
                    <div className='ibox-content'>
                        <Table data={data} columns={columns} modalName={'createUserModal'}/>
                    </div>
                </div>
            </div>
            <div className='modal inmodal' id='createUserModal' role='dialog' aria-hidden='true'>
                <div className='modal-dialog'>
                    <div className='modal-content animated bounceInRight'>
                        <div className='modal-header'>
                            <button type='button' className='close' data-dismiss='modal'><span
                                aria-hidden='true'>&times;</span><span className='sr-only'>Close</span>
                            </button>
                            <h4 className='modal-title'>Создать пользователя</h4>
                        </div>
                        <form onSubmit={createUser}>
                            <div className='modal-body'>
                                <div className='form-group'>
                                    <label>Имя</label>
                                    <input onChange={handleValue}
                                           value={formState.firstname}
                                           required={true}
                                           name='firstname'
                                           type='text'
                                           placeholder='Иван'
                                           className='form-control'/>
                                </div>
                                <div className='form-group'>
                                    <label>Фамилия</label>
                                    <input onChange={handleValue}
                                           value={formState.lastname}
                                           required={true}
                                           name='lastname'
                                           type='text'
                                           placeholder='Иванов'
                                           className='form-control'/>
                                </div>
                                <div className='form-group'>
                                    <label>Телефон</label>
                                    <input onChange={handleValue}
                                           value={formState.login}
                                           required={true}
                                           name='login'
                                           type='text'
                                           placeholder='+7XXXXXXXXXX'
                                           className='form-control'/>
                                </div>
                                <div className='form-group'>
                                    <label>Пароль (запишите его заранее)</label>
                                    <input onChange={handleValue}
                                           value={formState.password}
                                           required={true}
                                           name='password'
                                           type='text'
                                           placeholder='Пароль'
                                           pattern='.{6,}'
                                           title='Минимум 6 символов'
                                           className='form-control'/>
                                </div>
                                <div className='form-group'>
                                    <label>Старший техник</label>
                                    <Checkbox onChange={handleChecked}
                                              name='isSenior'
                                              label=' '
                                              checked={formState.isSenior}
                                              checkboxClass='icheckbox_square-green'
                                    />
                                </div>
                            </div>
                            <div className='modal-footer'>
                                <button type='button' className='btn btn-white' data-dismiss='modal'>Отмена</button>
                                <button type='submit' className='btn btn-primary'>Сохранить
                                    пользователя
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Users
