import React, {useState, useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import LitetechApi from '../modules/litetech-api';
import phoneFormat from '../modules/phoneFormat';

function Login() {
    let navigate = useNavigate();
    const [login, setLogin] = useState('+');
    const [password, setPassword] = useState('');

    useEffect(() => {
        redirectIfSessionExists()
        $('body').addClass('gray-bg');
    }, []);

    useEffect(() => () => {
        $('body').removeClass('gray-bg');
    }, []);


    function redirectIfSessionExists() {
        if (window.localStorage.getItem('session')) {
            navigate('../users', {replace: true});
        }
    }

    function handleChange(event) {
        const target = event.target.name;
        let val = event.target.value;
        if (target === 'password') setPassword(val)
        if (target === 'login') setLogin(phoneFormat(val))
    }

    async function signUp(event) {
        event.preventDefault();
        const response = await LitetechApi.signup(`${login}`, password);
        if (response.session) {
            window.localStorage.setItem('session', response.session)
            redirectIfSessionExists()
        }
    }

    return (
        <div>
            <div className="loginColumns animated fadeInDown">
                <div className="ibox-content">
                    <form onSubmit={signUp} className="m-t">
                        <div className="form-group">
                            <input value={login}
                                   onChange={handleChange}
                                   name="login"
                                   type="text"
                                   className="form-control"
                                   placeholder="Телефон +XXXXXXXXXXX"
                                   required={true}/>
                        </div>
                        <div className="form-group">
                            <input value={password}
                                   onChange={handleChange}
                                   name="password"
                                   type="password"
                                   className="form-control"
                                   placeholder="Пароль"
                                   required={true}/>
                        </div>
                        <button type="submit" className="btn btn-primary block full-width m-b">
                            Логин
                        </button>
                    </form>
                    <p className="m-t">
                        <small>Для получения логина и пароля свяжитесь с администратором</small>
                    </p>
                </div>
            </div>
        </div>
    )
}

export default Login
