import React, {useState, useCallback, useEffect, Fragment} from 'react';
import {Checkbox} from 'react-ui-icheck';
import {useTable, useSortBy, useGlobalFilter, useAsyncDebounce, usePagination, useExpanded} from 'react-table';
import Moment from 'react-moment';
import useWindowDimensions from './useWindowDimensions';

export function MomentCell({value}) {
    return value && <Moment>{value}</Moment>
}

export function CheckboxCell({value}) {
    return <Checkbox checkboxClassName='icheckbox_square-green'
                     disabled
                     checked={value}/>
}

export function ActionCell({value: {disabledEdit, disabledApprove, disabledRemove, editOnClick, approveOnClick, removeOnClick}}) {
    return <div className='inline-f'>
        {editOnClick && <div className='btn-group m-r-xs'>
            <button type='button'
                    className='btn btn-warning btn-w-s'
                    onClick={editOnClick()}
                    disabled={disabledEdit}
            >
                <i className='fa fa-pencil-square-o'></i>
            </button>
        </div>}
        {approveOnClick && <div className='btn-group m-r-xs'>
            <button type='button'
                    className='btn btn-success btn-w-s'
                    onClick={approveOnClick()}
                    disabled={disabledApprove}
            >
                Подтвердить
            </button>
        </div>}
        {removeOnClick && <div className='btn-group'>
            <button type='button'
                    className='btn btn-danger btn-w-s'
                    onClick={removeOnClick()}
                    disabled={disabledRemove}
            >
                <i className='fa fa-times'></i>
            </button>
        </div>}
    </div>
}

function GlobalFilter({globalFilter, setGlobalFilter}) {
    const [value, setValue] = useState(globalFilter)
    const onChange = useAsyncDebounce(value => {
        setGlobalFilter(value || undefined)
    }, 200)

    return (<input className='form-control form-control-sm'
                   value={value || ''}
                   onChange={e => {
                       setValue(e.target.value);
                       onChange(e.target.value);
                   }}
                   placeholder='Поиск'
    />)
}

export default function Table({
                                  data,
                                  columns: columnsProps = [],
                                  // columns = [],
                                  modalName,
                              }) {
    const [columns, setColumns] = useState(columnsProps)

    const {format} = useWindowDimensions();

    // Добавление раскрывающей кнопки
    useEffect(() => {
        const collapseColumn = {
            // Make an expander cell
            Header: () => null, // No header
            id: 'expander', // It needs an ID
            Cell: ({row}) => {
                return ((
                    // Use Cell to render an expander for each row.
                    // We can use the getToggleRowExpandedProps prop-getter
                    // to build the expander.
                    <span {...row.getToggleRowExpandedProps()}>
            {row.isExpanded ? <i className='fa fa-minus'/> : <i className='fa fa-plus'/>}
          </span>
                ))
            },
        }

        if (format === 'phone' && columnsProps.find(({collapsed}) => (collapsed))) {
            setColumns([
                collapseColumn,
                ...columnsProps,
            ])
        } else {
            setColumns([
                ...columnsProps,
            ])
        }


    }, [columnsProps, format])

    const filterTypes = React.useMemo(() => ({
        text: (rows, id, filterValue) => {
            return rows.filter(row => {
                const rowValue = row.values[id]
                return rowValue !== undefined ? String(rowValue)
                    .toLowerCase()
                    .startsWith(String(filterValue).toLowerCase()) : true
            })
        },
    }), [])

    // Раскрывающией сабэлемент
    const renderRowSubComponent = useCallback(
        ({row}) => (
            <table className='sub-table'>
                <tbody>
                {row.cells.map(cell => {
                        if (cell.column.collapsed) {
                            return (
                                <tr key={cell.column.Header}>
                                    <td className='font-bold'>{cell.column.Header} :</td>
                                    <td>{cell.render('Cell')}</td>
                                </tr>)
                        } else {
                            return null
                        }
                    },
                )}
                </tbody>
            </table>
        ),
        [],
    )

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        state,
        setGlobalFilter,
        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        visibleColumns,
        state: {pageIndex, pageSize},
    } = useTable({columns, data, filterTypes}, useGlobalFilter, useSortBy, useExpanded, usePagination)

    return (<div className='overflow-x-auto'>
        {/*Поиск и создание*/}
        <div className='space-between'>
            <div className='col-xs-10 row'>
                <GlobalFilter
                    globalFilter={state.globalFilter}
                    setGlobalFilter={setGlobalFilter}
                />
            </div>

            {modalName && <div>
                <button type='button'
                        className='btn btn-success btn-w-s'
                        data-toggle='modal'
                        data-target={`#${modalName}`}>
                    <i className='fa fa-plus'/>
                </button>
            </div>}
        </div>

        {/*Таблица*/}
        <table {...getTableProps()} className='table m-b-sm'>
            <thead>
            {headerGroups.map(headerGroup => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map(column => {
                        if (column.collapsed && format === 'phone') {
                            return null
                        }
                        return (<th {...column.getHeaderProps(column.getSortByToggleProps())}>
                            {column.render('Header')}
                            <span className='m-l-xs'>
                            {!column.disableSortBy && column.accessor ? column.isSorted ? column.isSortedDesc ?
                                    <i className='fa fa-sort-desc'/> : <i className='fa fa-sort-asc'/> :
                                <i className='fa fa-sort'/> : ''}
                        </span>
                        </th>)
                    })}
                </tr>))}
            </thead>

            <tbody {...getTableBodyProps()}>
            {page.map((row, index) => {
                prepareRow(row)
                return (
                    // Use a React.Fragment here so the table markup is still valid
                    <Fragment key={`0_${row.getRowProps().key}`}>
                        <tr {...row.getRowProps()} key={`1_${row.getRowProps().key}`} className={index % 2 && 'gray'}>
                            {row.cells.map(cell => {
                                if (cell.column.collapsed && format === 'phone') {
                                    return null
                                }
                                return (
                                    <td {...cell.getCellProps()}>
                                        {cell.render('Cell')}
                                    </td>)
                            })}
                        </tr>
                        {/*
                    If the row is in an expanded state, render a row with a
                    column that fills the entire length of the table.
                  */}
                        {row.isExpanded ? (
                            <tr {...row.getRowProps()} key={`2_${row.getRowProps().key}`}
                                className={index % 2 && 'gray'}>
                                <td colSpan={visibleColumns.length}>
                                    {/*
                          Inside it, call our renderRowSubComponent function. In reality,
                          you could pass whatever you want as props to
                          a component like this, including the entire
                          table instance. But for this example, we'll just
                          pass the row
                        */}
                                    {renderRowSubComponent({row})}
                                </td>
                            </tr>
                        ) : null}
                    </Fragment>
                )
            })}
            </tbody>
        </table>

        {/*Пагинация*/}
        <div>
            <div className='btn-group'>
                <button className='btn btn-sm btn-white' onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
                    <i className='fa fa-angle-double-left'></i>
                </button>
                <button className='btn btn-sm btn-white' onClick={() => previousPage()} disabled={!canPreviousPage}>
                    <i className='fa fa-angle-left'></i>
                </button>
                <button className='btn btn-sm btn-white' onClick={() => nextPage()} disabled={!canNextPage}>
                    <i className='fa fa-angle-right'></i>
                </button>
                <button className='btn btn-sm btn-white'
                        onClick={() => gotoPage(pageCount - 1)}
                        disabled={!canNextPage}>
                    <i className='fa fa-angle-double-right'></i>
                </button>
            </div>

            <span className='m-l-sm m-r-sm'>Страница <strong> {pageIndex + 1} из {pageOptions.length}</strong></span>

            <div className='inline'>
                <select
                    className='form-control'
                    value={pageSize}
                    onChange={e => {
                        setPageSize(Number(e.target.value))
                    }}
                >
                    {[10, 20, 30, 40, 50].map(pageSize => (<option key={pageSize} value={pageSize}>
                        Отображать {pageSize}
                    </option>))}
                </select>
            </div>
        </div>
    </div>)
}
