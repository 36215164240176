import axios from 'axios';
import swal from 'sweetalert';

const http = axios.create()

function joinURL(path, route) {
    const formattedPath = path[path.length - 1] === '/' ? path : `${path}/`;
    const formattedRoute = route[0] === '/' ? route.substring(1) : route;
    return formattedPath + formattedRoute;
}

export const setupInterceptors = navigate => {
    http.interceptors.response.use(res => {
        // success
        return res
    }, err => {
        const {status} = err.response

        if (status === 403 || status === 401) {
            window.localStorage.removeItem('session')
            navigate('/login', {replace: true});
        }

        return Promise.reject(err)
    })
}

class LitetechApi {
    async request(route, method, data = {}) {
        try {
            const headers = {}
            const session = window.localStorage.getItem('session')
            if (session) headers.Authorization = `Bearer ${session}`

            const response = await http.request({
                method,
                url: joinURL(CONFIG.techApi.url, route),
                headers,
                [['post', 'put'].includes(method) ? 'data' : 'params']: data,
            });

            if (String(response.status).indexOf('20') === 0) {
                return response.data;
            }
            return null;
        } catch (err) {
            const errMessage = (err.response.data && err.response.data.message) || 'Electra error'
            swal('Ошибка', Array.isArray(errMessage) ? errMessage.join(', ') : errMessage, 'error')
            console.log(`${this.constructor.name} err url=${route}`, err);
            return null;
        }
    }

    async signup(phone, password) {
        const res = await this.request(`/users/signup`, 'post', {phone, password});
        if (res) return res;
        throw new Error('Server error');
    }

    async createUser({firstname, lastname, login: phone, password, isSenior}) {
        const res = await this.request(`/users/create`, 'post', {
            first_name: firstname,
            last_name: lastname,
            phone,
            password,
            is_senior: isSenior,
        });
        if (res) return res;
        throw new Error('Server error');
    }

    async removeUser(userId) {
        const res = await this.request(`/users/remove`, 'post', {user_id: userId});
        if (res) return res;
        throw new Error('Server error');
    }

    async getAllUsers() {
        const res = await this.request(`/users/usersList`, 'get');
        if (res) return res;
        throw new Error('Server error');
    }

    async getAllShifts() {
        const res = await this.request(`/shifts/shiftsList`, 'get');
        if (res) return res;
        throw new Error('Server error');
    }

    async approveShift(shiftId) {
        const res = await this.request(`/shifts/approve`, 'post', {shift_id: shiftId});
        if (res) return res;
        throw new Error('Server error');
    }

    async removeShift(shiftId) {
        const res = await this.request(`/shifts/remove`, 'post', {shift_id: shiftId});
        if (res) return res;
        throw new Error('Server error');
    }

    async getAllowedStartMinutes() {
        const res = await this.request(`/shifts/allowedStartMinutes`, 'get');
        if (res) return res;
        throw new Error('Server error');
    }

    async createShift({
                          suggestStartedAt,
                          suggestFinishedAt,
                          userId,
                          isApproved,
                      }) {
        const res = await this.request(`/shifts/create`, 'post', {
            suggest_started_at: suggestStartedAt,
            suggest_finished_at: suggestFinishedAt,
            user_id: userId,
            is_approved: isApproved,
        });
        if (res) return res;
        throw new Error('Server error');
    }

    async editShift({
                        shiftId,
                        suggestStartedAt,
                        suggestFinishedAt,
                    }) {
        const res = await this.request(`/shifts/update`, 'post', {
            shift_id: shiftId,
            suggest_started_at: suggestStartedAt,
            suggest_finished_at: suggestFinishedAt,
        });
        if (res) return res;
        throw new Error('Server error');
    }
}

export default new LitetechApi()
