import React, {useEffect, useState, useRef} from 'react';
import {useLocation} from 'react-router-dom';

const routeNames = {
    '/users': 'Пользователи',
    '/shifts': 'Смены',
}

function WrapperHeader() {
    const [routeName, setRouteName] = useState('')
    const mounted = useRef();
    const location = useLocation();

    useEffect(() => {
        if (!mounted.current) {
            mounted.current = true;
        } else {
            Object.entries(routeNames).forEach(([routeName]) => {
                if (location.pathname.includes(routeName))
                    setRouteName(routeNames[routeName])
            })
        }
    });

    return (<div className="row wrapper border-bottom white-bg page-heading">
        <div className="col-lg-10">
            <h2>{routeName}</h2>
        </div>
        <div className="col-lg-2">

        </div>
    </div>)
}

export default WrapperHeader
