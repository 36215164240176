import React from 'react'
import Main from './components/layouts/Main';
import UsersView from './views/Users.js';
import ShiftsView from './views/Shifts/Shifts.view.js';
import LoginView from './views/Login';
import AxiosErrHandler from './components/common/AxiosErrHandler';
import {Route, Routes, Navigate} from 'react-router-dom';

function RequireAuth({children}) {
    let auth = window.localStorage.getItem('session');
    if (!auth) {
        return (<Navigate to="/login" replace/>);
    }

    return children;
}

export default function App() {
    return (<div>
        <AxiosErrHandler/>
        <Routes>
            <Route path="/login" element={<LoginView/>}/>
            <Route element={<RequireAuth><Main/></RequireAuth>}>
                <Route path="*" element={<Navigate to="/users" replace/>}/>
                <Route path="/users" element={<UsersView/>}> </Route>
                <Route path="/shifts" element={<ShiftsView/>}> </Route>
            </Route>
        </Routes>
    </div>)
};
