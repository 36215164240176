import React from 'react';
import {smoothlyMenu} from '../layouts/Helpers';
import {useNavigate} from 'react-router-dom';
import swal from 'sweetalert';

function TopHeader() {
    let navigate = useNavigate();

    function toggleNavigation(e) {
        e.preventDefault();
        $('body').toggleClass('mini-navbar');
        smoothlyMenu();
    }

    async function logOut() {
        const willLogOut = await swal({
            title: 'Выйти из учётной записи?',
            icon: 'warning',
            buttons: ['Отмена', true],
        })
        if (willLogOut) {
            window.localStorage.removeItem('session')
            navigate('/login', {replace: true});
        }
    }

    return (
        <div className="row border-bottom">
            <nav className="navbar navbar-static-top" role="navigation" style={{marginBottom: 0}}>
                <div className="navbar-header">
                    <a className="navbar-minimalize minimalize-styl-2 btn btn-primary "
                       onClick={toggleNavigation}
                       href="#">
                        <i className="fa fa-bars"></i>
                    </a>
                </div>
                <ul className="nav navbar-top-links navbar-right">
                    <li>
                        <a type="button" onClick={logOut}>
                            <i className="fa fa-sign-out"></i> Выйти из аккаунта
                        </a>
                    </li>
                </ul>
            </nav>
        </div>
    )
}

export default TopHeader
