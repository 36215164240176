import React, {useEffect} from 'react';
import Progress from '../common/Progress';
import Navigation from '../common/Navigation';
import TopHeader from '../common/TopHeader';
import {correctHeight, detectBody} from './Helpers';
import {Outlet} from 'react-router-dom';
import WrapperHeader from '../common/WrapperHeader';

function Main() {
    function handleName(name) {
        console.log(name);
    }

    useEffect(() => {
        // Run correctHeight function on load and resize window event
        $(window).bind('load resize', function () {
            correctHeight();
            detectBody();
        });

        // Correct height of wrapper after metisMenu animation.
        $('.metismenu a').click(() => {
            setTimeout(() => {
                correctHeight();
            }, 300)
        });
    }, []);

    let wrapperClass = 'gray-bg';
    return (
        <div id="wrapper">
            <Progress/>
            <Navigation/>

            <div id="page-wrapper" className={wrapperClass}>

                <TopHeader/>

                <WrapperHeader/>

                <Outlet sendName={handleName}/>

            </div>

        </div>

    )
}

export default Main
