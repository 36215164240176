import {useState, useEffect} from 'react';

// записывать по возрастанию
const widthFormats = [
    {format: 'phone', size: 575},
    {format: 'tablet', size: 768},
]

function getFormat(actualWidth) {
    const formatObj = widthFormats.find(({size}) => size >= actualWidth) || {format: 'desktop'}
    return formatObj.format
}

function getWindowDimensions() {
    const {innerWidth: width, innerHeight: height} = window;
    return {
        width,
        height,
        format: getFormat(width),
    };
}

// помощь замыканию
let prevFormat = ''

export default function useWindowDimensions() {
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

    function handleResize() {
        const {width: actualWidth, height} = getWindowDimensions()
        const actualFormat = getFormat(actualWidth)

        if (prevFormat === actualFormat) {
            return
        }
        prevFormat = actualFormat

        if (actualFormat) {
            setWindowDimensions({width: actualWidth, height, format: actualFormat});
        } else {
            setWindowDimensions({width: actualWidth, height, format: 'desktop'});
        }
    }

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return windowDimensions;
}
