import Datetime from 'react-datetime';
import React, {useState, useRef, useEffect} from 'react';
import Select from 'react-select';
import moment from 'moment';

// TODO разобрать спагетти
export default function DateTimePickerConstraint({
                                                     allowedHoursArr = [0],
                                                     onChange,
                                                     name,
                                                     value: propValue = new Date(),
                                                 }) {
    const [allowedHoursState, setAllowedHoursState] = useState(generateHoursList(allowedHoursArr));
    const [minuteState, setMinuteState] = useState(allowedHoursState[0]);
    const [dateState, setDateState] = useState(propValue);

    useEffect(() => {
        handleValue(dateState)
    }, [])

    useEffect(() => {
        setAllowedHoursState(generateHoursList(allowedHoursArr))
    }, [allowedHoursArr])

    useEffect(() => {
        const newDate = new Date(moment(propValue).startOf('day').add(minuteState.value || allowedHoursState[0].value, 'minutes').format())
        if (+newDate === +dateState) {
            return
        }
        setMinuteState(allowedHoursState[0])
        handleValue(newDate)
    }, [allowedHoursState, propValue])

    const dateTimeRef = useRef()

    function handleValue(value = dateState) {
        setDateState(value)
        onChange({target: {name, value}})
        dateTimeRef.current.setViewDate(value)
    }

    function goToToday() {
        dateTimeRef.current.navigate('days');
    }

    function generateHoursList(hoursArr) {
        return hoursArr.map((minutes) => {
            let hourStr = `${~~(minutes / 60)}`
            hourStr = hourStr.length === 1 ? '0' + hourStr : hourStr
            let minStr = `${minutes % 60}`
            minStr = minStr.length === 1 ? '0' + minStr : minStr
            return {value: minutes, label: `${hourStr}:${minStr}`}
        })
    }

    function setHours({value}) {
        setMinuteState(allowedHoursState.find(({value: allowedValue}) => allowedValue === value))
        const newDate = new Date(moment(dateState).startOf('day').add(value, 'minutes').format())
        handleValue(newDate)
    }

    function renderView(mode, renderDefault) {
        if (mode !== 'time') return renderDefault();
        return (
            <div className="rdtPicker">
                <div className="rdtTime">
                    <table>
                        <thead>
                        <tr>
                            <td className="rdtSwitch" colSpan="4">
                                <button onClick={goToToday}>К выбору даты</button>
                            </td>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td>
                                <div className="rdtCounters">
                                    <Select
                                        value={minuteState}
                                        onChange={setHours}
                                        options={allowedHoursState}
                                    />
                                </div>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }

    return (
        <Datetime
            ref={dateTimeRef}
            dateFormat="DD.MM.yyyy"
            isValidDate={(current) => current.isAfter(moment().subtract(1, 'day'))}
            value={dateState}
            renderView={renderView}
            onChange={handleValue}
            inputProps={{
                name: 'suggestStartedAt',
                onKeyDown: (e) => e.preventDefault(),
                required: true,
            }}
        />
    )
}
