import {useNavigate} from 'react-router-dom';
import {useEffect} from 'react';
import {setupInterceptors} from '../../modules/litetech-api';

export default function AxiosErrHandler() {
    const navigate = useNavigate()

    useEffect(() => {
        console.log('this effect is called once')
        setupInterceptors(navigate)
    }, [navigate])

    return null
}
